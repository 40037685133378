import React, { createContext, useCallback, useContext, useEffect } from 'react'

import { DrawCanvas, DrawCanvasMode } from '../Common/drawtools/DrawCanvas'
import { useInitOnce } from '../Common/hooks/useInitOnce'
import { useEffectNonNull } from '../Common/hooks/useEffectNonNull'
import { useRoomFeatureFlag, useRoomFeatureFlagsRest } from '../Common/utils/featureFlags'
import { RoomFeatureFlag } from '../Models/apiEntities'
import { PlaybackParticipant } from '../Common/janus/clients/playback/PlaybackClient'
import { IDrawCanvas } from '../Common/drawtools/types'

interface IDrawCanvasDisplayProvier extends IDrawCanvas {
  cleanUpCanvases: () => void
}

export const DrawCanvasDisplayProvider = createContext<IDrawCanvasDisplayProvier>(null!)

interface DrawCanvasDisplayContextProviderProps {
  children: React.ReactNode
  activeStreamId: string | null
  participants: PlaybackParticipant[]
}

export const DrawCanvasToolsContextProvider = ({
  children,
  participants,
  activeStreamId
}: DrawCanvasDisplayContextProviderProps) => {
  const [isDrawRecordingEnabled] = useRoomFeatureFlag(RoomFeatureFlag.drawRecording)
  const { ALLOW_ROOM_DRAW_TOOL: shouldAllowDrawTool = true } = useRoomFeatureFlagsRest()

  const drawCanvas = useInitOnce(
    () =>
      new DrawCanvas(
        {},
        {
          drawCanvasMode: DrawCanvasMode.Playback,
          isDrawRecordingEnabled,
          isRoomDrawingEnabled: shouldAllowDrawTool
        }
      )
  )

  useEffectNonNull(() => {
    drawCanvas.lc.on = () => {}
  }, [drawCanvas])

  useEffectNonNull(() => {
    drawCanvas.setActiveStream(activeStreamId)
  }, [activeStreamId, drawCanvas])

  useEffect(() => {
    drawCanvas.remotePublishersEventCallback(participants)
  }, [participants, drawCanvas])

  const cleanUpCanvases = useCallback(() => {
    drawCanvas.clearAllCanvases()
    participants.forEach((participant) => drawCanvas.teardownParticipant(participant))
  }, [drawCanvas, participants])

  const setLocalCanvasNode = useCallback(
    (node, type) => {
      drawCanvas.setLocalCanvasNode(node, type)
    },
    [drawCanvas]
  )

  const setParticipantCanvasNode = useCallback(
    (participant, index, node) => {
      drawCanvas.setParticipantCanvasNode(participant, index, node)
    },
    [drawCanvas]
  )

  const setVideoNode = useCallback(
    (ref) => {
      drawCanvas.setVideoNode(ref)
    },
    [drawCanvas]
  )

  const setThumbnailNode = useCallback(
    (participantId, node, type) => drawCanvas.setThumbnailNode(participantId, node, type),
    [drawCanvas]
  )

  return (
    <DrawCanvasDisplayProvider.Provider
      value={{
        setVideoNode,
        cleanUpCanvases,
        setThumbnailNode,
        setLocalCanvasNode,
        setParticipantCanvasNode
      }}
    >
      {children}
    </DrawCanvasDisplayProvider.Provider>
  )
}

export const useDrawCanvasDisplayContext = () => useContext(DrawCanvasDisplayProvider)
