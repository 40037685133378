import React, { useEffect, useState } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'

import { RoomSession } from '../../../../../Models/apiEntities'
import { usePlaybackClient } from './hooks/usePlaybackClient'
import PlaybackControlBar from './PlaybackControlBar/PlaybackControlBar'
import ParticipantsList from './ParticipantsList/ParticipantsList'
import { usePlaybackParticipants } from './hooks/usePlaybackParticipants'
import { FULLSCREEN_ELEMENT_ID } from '../../../../../Common/hooks/useFullScreen'

import './Playback.scss'
import { DrawCanvasToolsContextProvider } from '../../../../../Providers/DrawCanvasDisplayProvider'
import PlaybackMainVideoStream from './MainVideoStream/MainVideoStream'
import { StreamSelectionControl } from '../../RoomView/Room/LiveRoom/hooks/useLiveroom'
import { PlaybackState, usePlaybackController } from './hooks/usePlaybackController'
import { PulseLoader } from '../../RoomView/Room/LiveRoom/LiveRoomTransition/PulseLoader'

const CenteredBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
`

interface PlaybackProps {
  session: RoomSession
  iceServers
  isNotesOpen: boolean
  onNotesClick: () => void
  isAudioActive: boolean
  onAudioClicked: () => void
}

const Playback: React.FC<PlaybackProps> = ({
  session,
  iceServers,
  isNotesOpen,
  onNotesClick,
  isAudioActive,
  onAudioClicked,
}) => {
  const playbackClient = usePlaybackClient({ session, iceServers })
  const {
    participants,
    activeStream,
    selectParticipant,
    drawCanvas,
    selectedParticipantId,
  } = usePlaybackParticipants({
    playbackClient,
  })
  const {
    state: { playbackState },
  } = usePlaybackController({ playbackClient })

  const [percentage, setPercentage] = useState(0)
  useEffect(() => {
    setPercentage(100)
    const intervalId = setInterval(() => {
      setPercentage(prevValue => {
        if (prevValue >= 100) {
          return 0
        }
        return 100
      })
    }, 2000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    playbackState === PlaybackState.connecting ?
      <CenteredBox>
        <PulseLoader percentage={percentage} messages={[
          "We're getting your recording ready",
          "Just a moment, still loading",
          "Hang tight, the servers are thinking",
          "Your recording playback will begin soon"
        ]} />
      </CenteredBox>
      : playbackState === PlaybackState.error ?
        <>
          <CenteredBox><Box>Your recording failed to load. Please refresh to try again.<br /><small>If the error persists, please contact support.</small></Box></CenteredBox>
        </>
        :
        <DrawCanvasToolsContextProvider
          participants={drawCanvas.participants}
          activeStreamId={selectedParticipantId}
        >
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={100}
            minWidth={0}
            minHeight={0}
            height="100%"
            width="100%"
            position="relative"
            padding={1}
            id={FULLSCREEN_ELEMENT_ID}
          >
            <Box paddingBottom={1}>
              <ParticipantsList
                participants={participants}
                isAudioActive={isAudioActive}
                onParticipantClicked={selectParticipant}
              />
            </Box>

            <PlaybackMainVideoStream
              participants={participants}
              stream={{ active: activeStream } as StreamSelectionControl}
            />

            <Box position="absolute" width="100%" bottom={0} left={0} zIndex={2}>
              <PlaybackControlBar
                session={session}
                playbackClient={playbackClient}
                isNotesOpen={isNotesOpen}
                onNotesClick={onNotesClick}
                isAudioActive={isAudioActive}
                onAudioClicked={onAudioClicked}
              />
            </Box>
          </Box>
        </DrawCanvasToolsContextProvider>
  )
}

export default createFragmentContainer(React.memo(Playback), {
  iceServers: graphql`
    fragment Playback_iceServers on IceServer @relay(plural: true) {
      urls
      username
      credential
    }
  `,
})
