import React, { createContext, useCallback, useContext, useRef, useEffect } from 'react'
import { DrawCanvas, DrawCanvasMode } from '../Common/drawtools/DrawCanvas'
import { logger } from '../Common/log/Log'
import { toRGBA } from '../Common/utils/colorUtils'
import {
  DrawPreferences,
  useDrawPreferences
} from '../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/LiveRoomDrawer/Menu/hooks/useDrawPreferences'
import { ProfilePreferences } from '../Common/preferences/usePreferences'
import { useInitOnce } from '../Common/hooks/useInitOnce'
import { useEffectNonNull } from '../Common/hooks/useEffectNonNull'
import { Participant, Publisher } from '../Common/janus/clients/liveroom/LiveroomClient'
import { useRoomFeatureFlag, useRoomFeatureFlagsRest } from '../Common/utils/featureFlags'
import { RoomFeatureFlag } from '../Models/apiEntities'
import { IDrawCanvas } from '../Common/drawtools/types'

export enum DRAW_TOOLS {
  ARROW = 'ARROW',
  DASH = 'DASH',
  PENCIL = 'PENCIL',
  LINE = 'LINE',
  TEXT = 'TEXT',
  ERASER = 'ERASER',
  ELLIPSE = 'ELLIPSE',
  RECTANGLE = 'RECTANGLE'
}

export interface ColorChannels {
  red: number
  green: number
  blue: number
  alpha: number
}

export interface FontProps {
  drawFontIsBold?: boolean
  drawFontIsItalic?: boolean
  drawFontFamily?: string
  drawFontSize?: number
}

export enum ColorType {
  Primary = 'primary',
  Secondary = 'secondary'
}

export interface IDrawCanvasToolsProvider extends IDrawCanvas {
  setDrawTool: (type: DRAW_TOOLS) => void
  setDrawThickness: (thickness: number) => void
  setDrawStrokeColor: (color: ColorChannels) => void
  setDrawFillColor: (color: ColorChannels) => void
  setDrawFont: (fontProperties: FontProps) => void
  clearDrawCanvas: () => void
  drawPreferences: DrawPreferences
}

export const DrawCanvasToolsProvider = createContext<IDrawCanvasToolsProvider>(null!)

interface DrawCanvasToolsContextProviderProps {
  profilePreferences: ProfilePreferences
  children: React.ReactNode
  activeStreamId: string
  participants: Participant[]
  publisher: Publisher
}

export const DrawCanvasToolsContextProvider = ({
  profilePreferences,
  children,
  activeStreamId,
  participants,
  publisher
}: DrawCanvasToolsContextProviderProps) => {
  const { drawPreferences, setDrawPreferences } = useDrawPreferences(profilePreferences)
  const toolsRef = useRef<Record<DRAW_TOOLS, any>>(null!)
  const [isDrawRecordingEnabled] = useRoomFeatureFlag(RoomFeatureFlag.drawRecording)
  const { ALLOW_ROOM_DRAW_TOOL: shouldAllowDrawTool = true } = useRoomFeatureFlagsRest()

  const handleCanvasCreated = () => {
    logger.log('Canvas is created')

    const tools = {
      [DRAW_TOOLS.PENCIL]: new drawCanvas.LC.tools.Pencil(drawCanvas.lc),
      [DRAW_TOOLS.LINE]: new drawCanvas.LC.tools.Line(drawCanvas.lc),
      [DRAW_TOOLS.TEXT]: new drawCanvas.LC.tools.Text(),
      [DRAW_TOOLS.ERASER]: new drawCanvas.LC.tools.Eraser(drawCanvas.lc),
      [DRAW_TOOLS.ELLIPSE]: new drawCanvas.LC.tools.Ellipse(drawCanvas.lc),
      [DRAW_TOOLS.RECTANGLE]: new drawCanvas.LC.tools.Rectangle(drawCanvas.lc),
      [DRAW_TOOLS.ARROW]: new drawCanvas.LC.tools.Line(drawCanvas.lc),
      [DRAW_TOOLS.DASH]: new drawCanvas.LC.tools.Line(drawCanvas.lc)
    }

    tools[DRAW_TOOLS.ARROW].hasEndArrow = true
    tools[DRAW_TOOLS.DASH].isDashed = true

    toolsRef.current = tools

    if (tools[drawPreferences.drawTool]) {
      setDrawTool(drawPreferences.drawTool)
    }

    setDrawFont({
      drawFontIsBold: drawPreferences.drawFontIsBold,
      drawFontIsItalic: drawPreferences.drawFontIsItalic,
      drawFontFamily: drawPreferences.drawFontFamily,
      drawFontSize: drawPreferences.drawFontSize
    })
    setDrawThickness(drawPreferences.drawThickness)
    setDrawStrokeColor({
      alpha: drawPreferences.drawStrokeColorAlpha,
      red: drawPreferences.drawStrokeColorRed,
      green: drawPreferences.drawStrokeColorGreen,
      blue: drawPreferences.drawStrokeColorBlue
    })
    setDrawFillColor({
      alpha: drawPreferences.drawFillColorAlpha,
      red: drawPreferences.drawFillColorRed,
      green: drawPreferences.drawFillColorGreen,
      blue: drawPreferences.drawFillColorBlue
    })
  }

  const drawCanvas = useInitOnce(
    () =>
      new DrawCanvas(
        {
          canvasCreated: handleCanvasCreated
        },
        {
          drawCanvasMode: DrawCanvasMode.Liveroom,
          isDrawRecordingEnabled,
          isRoomDrawingEnabled: shouldAllowDrawTool
        }
      )
  )

  useEffect(() => {
    return () => {
      drawCanvas.teardown()
    }
  }, [drawCanvas])

  const setDrawPreferencesHandler = useCallback(
    (newSettings) => {
      setDrawPreferences({
        ...drawPreferences,
        ...newSettings
      })
    },
    [drawPreferences, setDrawPreferences]
  )

  const setDrawTool = useCallback(
    (toolName: DRAW_TOOLS) => {
      const drawTool = toolName.toUpperCase()

      setDrawPreferencesHandler({ drawTool })

      const tool = toolsRef.current[drawTool]

      drawCanvas.lc.setTool(tool)
    },
    [drawCanvas.lc, setDrawPreferencesHandler]
  )

  const setDrawThickness = useCallback(
    (level: number) => {
      setDrawPreferencesHandler({
        drawThickness: level
      })

      Object.keys(toolsRef.current).forEach((tool) => {
        toolsRef.current[tool].strokeWidth = level
      })

      drawCanvas.lc.tool.strokeWidth = level
    },
    [drawCanvas, setDrawPreferencesHandler]
  )

  const setDrawFillColor = useCallback(
    (colorChannels: ColorChannels) => {
      setDrawPreferencesHandler({
        drawFillColorRed: colorChannels.red,
        drawFillColorGreen: colorChannels.green,
        drawFillColorBlue: colorChannels.blue,
        drawFillColorAlpha: colorChannels.alpha
      })
      drawCanvas.lc.setColor(ColorType.Secondary, toRGBA(colorChannels))
    },
    [drawCanvas, setDrawPreferencesHandler]
  )

  const setDrawStrokeColor = useCallback(
    (colorChannels: ColorChannels) => {
      setDrawPreferencesHandler({
        drawStrokeColorRed: colorChannels.red,
        drawStrokeColorGreen: colorChannels.green,
        drawStrokeColorBlue: colorChannels.blue,
        drawStrokeColorAlpha: colorChannels.alpha
      })
      drawCanvas.lc.setColor(ColorType.Primary, toRGBA(colorChannels))
    },
    [drawCanvas, setDrawPreferencesHandler]
  )

  const toFontString = useCallback(
    (font: FontProps) => {
      const fontSetting = {
        ...drawPreferences,
        ...font
      }

      const italic = fontSetting.drawFontIsItalic ? 'italic' : ''
      const bold = fontSetting.drawFontIsBold ? 'bold' : ''
      const size = `${fontSetting.drawFontSize}px`
      const family = fontSetting.drawFontFamily

      return `${italic} ${bold} ${size} ${family}`
    },
    [drawPreferences]
  )

  const setDrawFont = useCallback(
    (font: FontProps) => {
      const fontString = toFontString(font)
      setDrawPreferencesHandler(font)

      drawCanvas.lc.tool.font = fontString
      drawCanvas.lc.trigger('setFont', fontString) // to update font in current textarea if it exists
    },
    [drawCanvas, setDrawPreferencesHandler, toFontString]
  )

  const clearDrawCanvas = useCallback(() => {
    drawCanvas.lc.clear()
  }, [drawCanvas])

  const setLocalCanvasNode = useCallback(
    (node, type) => {
      drawCanvas.setLocalCanvasNode(node, type)
    },
    [drawCanvas]
  )

  const setParticipantCanvasNode = useCallback(
    (participant, index, node) => {
      drawCanvas.setParticipantCanvasNode(participant, index, node)
    },
    [drawCanvas]
  )

  const setVideoNode = useCallback(
    (ref) => {
      drawCanvas.setVideoNode(ref)
    },
    [drawCanvas]
  )

  const setThumbnailNode = useCallback(
    (participantId, node, type) => drawCanvas.setThumbnailNode(participantId, node, type),
    [drawCanvas]
  )

  useEffectNonNull(() => {
    drawCanvas.localPublisherEventCallback(publisher)
  }, [publisher, drawCanvas])

  useEffect(() => {
    drawCanvas.remotePublishersEventCallback(participants)
  }, [participants, drawCanvas])

  useEffectNonNull(() => {
    drawCanvas.setActiveStream(activeStreamId)
  }, [activeStreamId, drawCanvas])

  return (
    <DrawCanvasToolsProvider.Provider
      value={{
        setDrawTool,
        setDrawThickness,
        setDrawStrokeColor,
        setDrawFillColor,
        setDrawFont,
        clearDrawCanvas,
        setLocalCanvasNode,
        setParticipantCanvasNode,
        setVideoNode,
        setThumbnailNode,
        drawPreferences
      }}
    >
      {children}
    </DrawCanvasToolsProvider.Provider>
  )
}

export const useDrawCanvasToolsContext = () => useContext(DrawCanvasToolsProvider)
