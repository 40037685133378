import { RTCConnectivityStats } from './useGetRTCConnectivity'

const bytesToString = (bytes: number | undefined) => {
  if (bytes === undefined) return undefined

  // TODO: these are in bits, but I think they should be in bytes. Making them bits to be the same as desktop.

  return `${((bytes * 8) / 1000000).toFixed(2)} Mbps`

  // if (bytes * 8 > 1000) return `${((bytes * 8) / 1000).toFixed(1)} Kbps`

  // return `${(bytes * 8).toFixed(0)} bps`
}

export const displayNetworkConnectivityStats = (stats?: Partial<RTCConnectivityStats>) => {
  if (!stats)
    return {
      uploadBitRate: undefined,
      downloadBitRate: undefined,
      rtt: undefined,
      jitter: undefined,
    }
  const {
    uploadByteRate: _uploadByteRate,
    downloadByteRate: _downloadByteRate,
    uploadBitRate: _uploadBitRate,
    downloadBitRate: _downloadBitRate,
    rtt: _rtt,
    jitter: _jitter,
  } = stats

  const uploadBitRate: string | undefined = _uploadBitRate
    ? bytesToString(_uploadBitRate)
    : bytesToString(_uploadByteRate)
  const downloadBitRate: string | undefined = _downloadBitRate
    ? bytesToString(_downloadBitRate)
    : bytesToString(_downloadByteRate)
  const rtt: string | undefined = Number(_rtt) >= 0 ? `${Number(_rtt).toFixed(2)} ms` : undefined
  const jitter: string | undefined =
    Number(_jitter) >= 0 ? `${Number(_jitter).toFixed(2)} ms` : undefined

  return { uploadBitRate, downloadBitRate, rtt, jitter }
}
