import React, { useEffect, useMemo, useState } from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { hasIn, isEmpty } from 'ramda'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import MicrophoneTester from '../MicrophoneTester/MicrophoneTester'
import ProgressButton from '../../../../../../Components/ProgressButton/ProgressButton'
import MicrophoneToggleButton from '../Buttons/MicrophoneToggleButton/MicrophoneToggleButton'
import CameraToggleButton from '../Buttons/CameraToggleButton/CameraToggleButton'
import DeviceSelectionButton from '../Buttons/DeviceSelectionButton/DeviceSelectionButton'
import StreamPreview from './StreamPreview/StreamPreview'
import { useAudioStream } from '../../../../../../Common/media/hooks/useAudioStream'
import { useVideoStream } from '../../../../../../Common/media/hooks/useVideoStream'
import { MediaSelection, MediaSetupState } from '../hooks/useMediaSetup'
import { LocalDevicesActions, LocalDevicesState } from '../hooks/useLocalDevicesState'
import { MediaDevicesInfo } from '../hooks/useMediaDevices'
import { useDialog } from '../../../../../../Components/RoomDialog/hooks/useDialog'
import DeviceSelectionDialog from '../../../RoomView/DeviceSelectionDialog/DeviceSelectionDialog'

import './Lobby.scss'
import { useModerationWebSocket } from '../../../../../../Common/hooks/useModerationWebSocket'
import { useNavigation } from '../../../../../../Common/hooks/useNavigation'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useSession } from '../../../../hooks/useSession'
import useApi from '../../../../../../API/useApi'

const useStyles = makeStyles({
  h1: {
    fontSize: 53,
  },
})

interface LobbyProps {
  handleGoLiveClick: () => void
  localDevicesActions: LocalDevicesActions
  localDevicesState: LocalDevicesState
  mediaSetupState: MediaSetupState
  mediaDevices: MediaDevicesInfo | null
  mediaSelection: MediaSelection | null
  room: {
    id: string
    displayName?: string
  }
  participantCount: number
  dndMode: boolean
  canPublish: boolean
  handleMediaSelectionChange: (mediaSelection: MediaSelection) => Promise<void>
  refreshGraphQLQuery: () => void
  getRoomAccess: () => void
}

export const Lobby: React.FC<LobbyProps> = ({
  room: { id, displayName },
  participantCount,
  dndMode,
  handleGoLiveClick,
  localDevicesState,
  localDevicesActions,
  mediaDevices,
  mediaSetupState,
  mediaSelection,
  handleMediaSelectionChange,
  canPublish,
  getRoomAccess,
}: LobbyProps) => {
  const alert = useAlerts()
  const { userProfile } = useSession()
  const camera = localDevicesState.isCameraEnabled ? mediaSelection?.camera : null
  const videoStream = useVideoStream({
    camera,
    speaker: null,
  })
  const microphone = localDevicesState.isMicrophoneEnabled ? mediaSelection?.microphone : null
  const audioStream = useAudioStream({
    microphone,
    speaker: null,
  })
  const [
    isDeviceSelectionDialogOpened,
    openDeviceSelectionDialog,
    closeDeviceSelectionDialog,
  ] = useDialog()
  const classes = useStyles()
  const hasMicrophoneDevice = useMemo(
    () =>
      !isEmpty(mediaDevices) &&
      hasIn('devices', mediaDevices!.microphone) &&
      !!mediaDevices!.microphone.devices.length,
    [mediaDevices]
  )
  const hasCameraDevice = useMemo(
    () =>
      !isEmpty(mediaDevices) &&
      hasIn('devices', mediaDevices!.camera) &&
      !!mediaDevices!.camera.devices.length,
    [mediaDevices]
  )

  function isRequestAccessEvent(message) {
    const parsedMessage = JSON.parse(message.data);
    return (parsedMessage.type === "dnd" && (parsedMessage.action === 'participantApproved' || parsedMessage.action === 'participantDenied'))
      || (parsedMessage.type === "room" && (parsedMessage.action === 'roomStateUpdated' || parsedMessage.action === 'roomParticipantsUpdated'));
  }

  const { sendJsonMessage, lastJsonMessage } = useModerationWebSocket(id, isRequestAccessEvent)
  const [isLoading, setIsLoading] = useState(false)
  const { goToDashboardWithState } = useNavigation()
  const { Room } = useApi()

  const handleRequestAccessClick = () => {
    setIsLoading(true)
    const message = {
      type: 'dnd',
      action: 'requestAccess',
      timestamp: new Date().toISOString()
    }
    sendJsonMessage(message);
  }

  // Run when a new Moderation WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    if (lastJsonMessage?.type === 'dnd' && userProfile?.id === lastJsonMessage?.data?.profileId) {
      switch (lastJsonMessage?.action) {
        case 'participantApproved':
          setIsLoading(false);
          Room.joinRoom(id)
          handleGoLiveClick();
          break;
        case 'participantDenied':
          setIsLoading(false);
          goToDashboardWithState(displayName, true);
          break;
        default:
          // Just ignore all other messages if somehow the filter fails
          break;
      }
    }
    if (lastJsonMessage?.type === 'room') {
      switch (lastJsonMessage?.action) {
        case 'roomStateUpdated':
          //update the room value with the latest
          getRoomAccess()
          break;
        case 'roomParticipantsUpdated':
        case 'roomPermissionsUpdated':
          if (!dndMode) getRoomAccess()
          break;
        default:
          // Just ignore all other messages if somehow the filter fails
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])

  useEffect(() => {
    if (isLoading) {
      alert('Waiting for the host to respond', AlertType.info)
    }
  }, [isLoading, alert])

  return (
    <Box className="lobby">
      <Grid className="lobby-grid" container>
        <Grid className="lobby-camera-preview-column" item xl={9} lg={8} md={7} sm={12}>
          <StreamPreview
            isCameraEnabled={localDevicesState.isCameraEnabled}
            isMicrophoneEnabled={localDevicesState.isMicrophoneEnabled}
            mediaSetupState={mediaSetupState}
            mediaDevices={mediaDevices}
            stream={videoStream}
            isCameraSetToNone={!mediaSelection?.camera}
            canPublish={canPublish}
          />
        </Grid>
        <Grid className="lobby-room-data-column" item xl={3} lg={4} md={5} sm={12}>
          <Box padding={9}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant={'h1'} className={classes.h1}>{`Welcome\n`}</Typography>
                    <Typography variant={'h1'} className={classes.h1}>{`to the lobby`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'} noWrap>
                      <strong>Room:</strong> {displayName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    { dndMode ? <Typography variant={'body1'}>
                        <strong>Occupancy:</strong> This room is in <strong>Do not disturb</strong> mode and participant info is not shown
                      </Typography>
                      : ( participantCount === 0 ? <Typography variant={'body1'}>
                            <strong>Occupancy:</strong> There is no one in this room
                          </Typography>
                          : <Typography variant={'body1'}>
                            <strong>Occupancy:</strong> Currently {participantCount} participant{participantCount !== 1 ? `s` : ``} here
                          </Typography>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item>
                    <MicrophoneToggleButton
                      className="lobby-room-button"
                      data-testid="microphone-toggle-button"
                      disabled={!mediaDevices?.microphone?.hasPermission}
                      isActive={localDevicesState.isMicrophoneEnabled}
                      onToggle={localDevicesActions.setIsMicrophoneEnabled}
                      medium
                      noDevices={!hasMicrophoneDevice}
                      microphoneSetToNone={!mediaSelection?.microphone}
                    />
                  </Grid>
                  <Grid item>
                    <CameraToggleButton
                      className="lobby-room-button"
                      data-testid="camera-toggle-button"
                      disabled={!mediaDevices?.camera?.hasPermission}
                      isActive={localDevicesState.isCameraEnabled}
                      onToggle={localDevicesActions.setIsCameraEnabled}
                      medium
                      noDevices={!hasCameraDevice}
                      cameraSetToNone={!mediaSelection?.camera}
                    />
                  </Grid>
                  <Grid item>
                    <DeviceSelectionButton
                      className="lobby-room-button"
                      data-testid="open-device-selection-button"
                      onClick={openDeviceSelectionDialog}
                      medium
                    />
                    <DeviceSelectionDialog
                      isOpen={isDeviceSelectionDialogOpened}
                      mediaDevices={mediaDevices!}
                      currentMediaSelection={mediaSelection!}
                      onUpdate={handleMediaSelectionChange}
                      onClose={closeDeviceSelectionDialog}
                      //pass null because csdResults shouldn't show in lobby
                      csdResults={null}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MicrophoneTester label={'Microphone level'} stream={audioStream} />
              </Grid>
              { dndMode ?
                <Grid item xs={12}>
                  <ProgressButton
                    data-testid="request-access-button"
                    color="secondary"
                    isLoading={isLoading}
                    fullWidth
                    onClick={handleRequestAccessClick}
                  >
                    { canPublish ? 'Request access to the room' : 'Request access to the room as a viewer'}
                  </ProgressButton>
                </Grid> :
                <Grid item xs={12}>
                  <ProgressButton
                    data-testid="go-live-button"
                    color="secondary"
                    isLoading={false}
                    fullWidth
                    onClick={handleGoLiveClick}
                  >
                    { canPublish ? 'Enter room' : 'Enter room as a viewer'}
                  </ProgressButton>
                </Grid>
              }
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default createFragmentContainer(React.memo(Lobby), {
  room: graphql`
    fragment Lobby_room on Liveroom {
      id
      displayName
    }
  `,
})
