import './ThumbnailConnectivity.scss'
import React from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface ThumbnailConnectivityProps {
  downloadBitRate?: string
  uploadBitRate?: string
  rtt?: string
  jitter?: string
  close: () => void
}

export const ThumbnailConnectivity = (props: ThumbnailConnectivityProps) => {
  const { downloadBitRate, uploadBitRate, rtt, jitter, close } = props
  return (
    <div className="thumbnail-connectivity-stats">
      <IconButton className="tcs-close" size="small" onClick={close}>
        <CloseIcon />
      </IconButton>
      <div className="tcs-item">DOWNLOAD SPEED: </div>
      <div>{downloadBitRate ?? '--'}</div>
      <div className="tcs-item">UPLOAD SPEED: </div>
      <div>{uploadBitRate ?? '--'}</div>
      <div className="tcs-item">ROUND TRIP TIME: </div>
      <div>{rtt ?? '--'}</div>
      <div className="tcs-item">JITTER: </div>
      <div>{jitter ?? '--'}</div>
    </div>
  )
}
