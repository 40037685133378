import { useEffect, useState } from 'react'
import useApi from '../../../../../../../API/useApi'
import { IPostConnectivityLog } from '../../../../../../../Models/apiEntities'
import { useConnectivityContext } from '../../../../../../../Providers/ConnectivityProvider'
import { useRoom } from '../../../../../../../Providers/RoomProvider'

const useUploadConnectivityInfo = (currentProfile, streamId) => {
  const [, setIterations] = useState(0)
  const { currentRTCConnectivityStats } = useConnectivityContext()
  const { ConnectivityLogs } = useApi()
  const { id: roomId } = useRoom()

  useEffect(() => {
    setIterations((i) => {
      if (i > 0 && i % 20 === 0 && currentRTCConnectivityStats) {
        const payload: IPostConnectivityLog = {
          uploadBitRate: Math.round(currentRTCConnectivityStats.uploadByteRate),
          downloadBitRate: Math.round(currentRTCConnectivityStats.downloadByteRate),
          rtt: Math.round(currentRTCConnectivityStats.rtt),
          createdAt: new Date(),
          id: currentProfile.id,
          streamId: streamId,
          jitter: Math.round(currentRTCConnectivityStats.jitter),
        }
        ConnectivityLogs.postLog(roomId, payload)
        return 0
      }
      return i + 1
    })
  }, [currentRTCConnectivityStats, ConnectivityLogs, currentProfile.id, roomId, streamId])
}

export default useUploadConnectivityInfo
